import React, { useState, useCallback, useEffect, useRef } from 'react';
import '98.css';
import './App.css';
import WindowManager from './WindowManager';
import localFileManager from './LocalFileManager';
import tinyCloudManager from './TinyCloudManager';
import networkIcon from './icons/network-32x32.png';
import computerIcon from './icons/my-computer-32x32.png';
import folderIcon from './icons/my-documents-folder-32x32.png';
import notepadIcon from './icons/notepad-file-32x32.png';
import FileBrowser from './FileBrowser';

const Notepad = ({ id, setWindows, initialContent = '', fileName = 'Untitled.txt', path = 'C:/My Documents/' }) => {
  const [content, setContent] = useState(initialContent);
  const [currentFileName, setCurrentFileName] = useState(fileName);
  const [currentPath, setCurrentPath] = useState(path);

  const handleNewFile = () => {
    setContent('');
    setCurrentFileName('Untitled.txt');
    setCurrentPath('C:/My Documents/');
    updateWindowTitle('Untitled.txt');
  };

  const handleOpenFile = async () => {
    try {
      let items;
      if (currentPath.startsWith('TinyCloud:')) {
        items = await tinyCloudManager.listFiles(currentPath);
      } else {
        items = await localFileManager.getItems(currentPath);
      }
      // Implement a file selection dialog here
      // For now, we'll just open the first file in the list
      if (items.length > 0) {
        let fileContent;
        if (currentPath.startsWith('TinyCloud:')) {
          fileContent = await tinyCloudManager.downloadFile(items[0].path);
        } else {
          fileContent = await localFileManager.readFile(items[0].path);
        }
        setContent(fileContent);
        setCurrentFileName(items[0].name);
        updateWindowTitle(items[0].name);
      }
    } catch (error) {
      console.error('Error opening file:', error);
    }
  };

  const handleSaveFile = async () => {
    let fileNameToSave = currentFileName;
    
    if (fileNameToSave === 'Untitled.txt') {
      const newFileName = prompt('Enter a file name:', 'Untitled.txt');
      if (newFileName === null) {
        // User cancelled the prompt
        return;
      }
      fileNameToSave = newFileName.trim() || 'Untitled.txt';
    }

    try {
      const filePath = `${currentPath}${fileNameToSave}`;
      if (currentPath.startsWith('TinyCloud:')) {
        await tinyCloudManager.uploadFile(filePath, content);
      } else {
        await localFileManager.createFile(filePath, content);
      }
      setCurrentFileName(fileNameToSave);
      updateWindowTitle(fileNameToSave);
    } catch (error) {
      console.error('Error saving file:', error);
    }
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
    updateWindowTitle(`${currentFileName}*`);
  };

  const updateWindowTitle = (title) => {
    setWindows(prevWindows => 
      prevWindows.map(window => 
        window.id === id ? { ...window, title } : window
      )
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div className="window-menu" style={{ display: 'flex', borderBottom: '1px solid #c0c0c0' }}>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }} onClick={handleNewFile}>New</div>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }}>
          <label style={{ cursor: 'inherit' }}>
            Open
            <input type="file" style={{ display: 'none' }} onChange={handleOpenFile} />
          </label>
        </div>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }} onClick={handleSaveFile}>Save</div>
      </div>
      <textarea 
        value={content} 
        onChange={handleContentChange}
        style={{ flex: 1, resize: 'none', border: 'none', outline: 'none' }}
      />
    </div>
  );
};

const Taskbar = ({ windows, setActiveWindow, openNotepad }) => (
  <div className="taskbar" style={{position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'silver', display: 'flex', alignItems: 'center', padding: '2px'}}>
    <button className="start-button" onClick={() => openNotepad()}>Start</button>
    <div style={{flex: 1, display: 'flex', marginLeft: '10px'}}>
      {windows.map(window => (
        <button key={window.id} onClick={() => setActiveWindow(window.id)} style={{marginRight: '5px'}}>
          {window.title}
        </button>
      ))}
    </div>
    <span>{new Date().toLocaleTimeString()}</span>
  </div>
);

// Modified DesktopIcon component
const DesktopIcon = ({ icon, label, onDoubleClick, position, onDragStart, onDrag, onDragEnd }) => {
  const [isDragging, setIsDragging] = useState(false);
  const iconRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    onDragStart(e);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      onDrag(e);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    onDragEnd();
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div
      ref={iconRef}
      className="desktop-icon"
      onDoubleClick={onDoubleClick}
      onMouseDown={handleMouseDown}
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        textAlign: 'center',
        cursor: isDragging ? 'grabbing' : 'grab',
        userSelect: 'none',
      }}
    >
      <img src={icon} alt={label} style={{ width: '32px', height: '32px' }} />
      <div style={{ color: 'white', textShadow: '1px 1px 1px black' }}>{label}</div>
    </div>
  );
};

// Modified Desktop component
const Desktop = ({ children, icons, updateIconPosition }) => (
  <div className="desktop" style={{height: 'calc(100vh - 28px)', backgroundColor: 'teal', position: 'relative'}}>
    {icons.map((icon, index) => (
      <DesktopIcon
        key={index}
        {...icon}
        onDragStart={(e) => {
          const rect = e.target.getBoundingClientRect();
          icon.dragOffset = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
          };
        }}
        onDrag={(e) => {
          const newX = e.clientX - icon.dragOffset.x;
          const newY = e.clientY - icon.dragOffset.y;
          updateIconPosition(index, { x: newX, y: newY });
        }}
        onDragEnd={() => {
          delete icon.dragOffset;
        }}
      />
    ))}
    {children}
  </div>
);

function App() {
  const [windows, setWindows] = useState([]);
  const [activeWindow, setActiveWindow] = useState(null);
  
  const openNotepad = useCallback(async (fileName = 'Untitled.txt', path = "C:/My Documents/") => {
    // Check if a window with the same file name already exists
    const existingWindow = windows.find(window => window.title === fileName);
    if (existingWindow) {
      setActiveWindow(existingWindow.id);
      setWindows(prevWindows =>
        prevWindows.map(window =>
          window.id === existingWindow.id ? { ...window, isMinimized: false } : window
        )
      );
      return;
    }

    let initialContent = '';
    if (fileName !== 'Untitled.txt') {
      try {
        const filePath = `${path}${fileName}`;
        if (path.startsWith('TinyCloud:')) {
          initialContent = await tinyCloudManager.downloadFile(filePath) || '';
        } else {
          initialContent = await localFileManager.readFile(filePath) || '';
        }
      } catch (error) {
        console.error('Error loading file:', error);
      }
    }

    // Create a new window
    const id = `notepad-${Date.now()}`;
    const newWindow = {
      id,
      component: Notepad,
      props: { id, setWindows, initialContent, fileName, path },
      position: { x: 50 + (windows.length * 30), y: 50 + (windows.length * 30) },
      title: fileName,
      size: { width: 400, height: 300 }
    };
    setWindows(prevWindows => [...prevWindows, newWindow]);
    setActiveWindow(id);
  }, [windows, setWindows, setActiveWindow]);


  const openFileBrowser = useCallback((defaultPath = 'C:') => {
    const id = `file-browser-${Date.now()}`;
    const newWindow = {
      id,
      component: FileBrowser,
      props: { id, setWindows, openNotepad, defaultPath },
      position: { x: 50 + (windows.length * 30), y: 50 + (windows.length * 30) },
      title: 'My Computer',
      size: { width: 600, height: 400 }
    };
    setWindows(prevWindows => [...prevWindows, newWindow]);
    setActiveWindow(id);
  }, [windows, setWindows, setActiveWindow, openNotepad]);

  const openTinyCloud = useCallback(async () => {
    try {
      await tinyCloudManager.connect();
      openFileBrowser('TinyCloud:');
    } catch (error) {
      window.alert(error.message);
    }
  }, [openFileBrowser]);

  const [desktopIcons, setDesktopIcons] = useState([
    {
      icon: networkIcon,
      label: 'TinyCloud',
      onDoubleClick: openTinyCloud,
      position: { x: 20, y: 10 },
    },
    {
      icon: computerIcon,
      label: 'My Computer',
      onDoubleClick: () => {openFileBrowser()},
      position: { x: 10, y: 80 },
    },
    {
      icon: folderIcon,
      label: 'My Documents',
      onDoubleClick: () => {openFileBrowser("C:/My Documents")},
      position: { x: 10, y: 150 },
    },
    {
      icon: notepadIcon,
      label: 'demo.txt',
      onDoubleClick: () => openNotepad('demo.txt', "C:/Desktop/"),
      position: { x: 24, y: 220 },
    }
  ]);

  useEffect(() => {
    // Initialize the file system
    const initFileSystem = async () => {
      await localFileManager.initializeFileSystem();
    };
    initFileSystem();
  }, []);

  const closeWindow = (id) => {
    setWindows(windows.filter(window => window.id !== id));
    if (activeWindow === id) {
      setActiveWindow(windows[windows.length - 2]?.id || null);
    }
  };

  const handleDrag = useCallback((id, newPosition) => {
    setWindows(prevWindows =>
      prevWindows.map(window =>
        window.id === id ? { ...window, position: newPosition } : window
      )
    );
  }, []);

  const updateIconPosition = useCallback((index, newPosition) => {
    setDesktopIcons(prevIcons => 
      prevIcons.map((icon, i) => 
        i === index ? { ...icon, position: newPosition } : icon
      )
    );
  }, []);

  const maximizeWindow = (id) => {
    setWindows(windows.map(window => 
      window.id === id ? { ...window, isMaximized: !window.isMaximized } : window
    ));
  };

  const minimizeWindow = (id) => {
    setWindows(windows.map(window => 
      window.id === id ? { ...window, isMinimized: true } : window
    ));
    if (activeWindow === id) {
      setActiveWindow(windows.find(w => w.id !== id)?.id || null);
    }
  };

  return (
    <div className="App">
      <Desktop icons={desktopIcons} updateIconPosition={updateIconPosition}>
        <WindowManager
          windows={windows.filter(w => !w.isMinimized)}
          closeWindow={closeWindow}
          onDrag={handleDrag}
          activeWindow={activeWindow}
          setActiveWindow={setActiveWindow}
          maximizeWindow={maximizeWindow}
          minimizeWindow={minimizeWindow}
        />
      </Desktop>
      <Taskbar 
        windows={windows}
        setActiveWindow={(id) => {
          setActiveWindow(id);
          setWindows(prevWindows =>
            prevWindows.map(window =>
              window.id === id ? { ...window, isMinimized: false } : window
            )
          );
        }}
        openNotepad={openNotepad}
      />
    </div>
  );
}

export default App;