import localforage from 'localforage';

class LocalFileManager {
  constructor() {
    this.fileSystem = localforage.createInstance({
      name: "fileSystem"
    });
  }

  async initializeFileSystem() {
    const isInitialized = await this.fileSystem.getItem('isInitialized');
    if (!isInitialized) {
      await this.fileSystem.setItem('C:/Desktop', { type: 'directory', content: null });
      await this.fileSystem.setItem('C:/My Documents', { type: 'directory', content: null });
      await this.fileSystem.setItem('C:/Desktop/demo.txt', { type: 'file', content: 'Hello, World!' });
      await this.fileSystem.setItem('isInitialized', true);
    }
  }

  async getItems(path) {
    const keys = await this.fileSystem.keys();
    console.log(keys);
    const items = await Promise.all(keys
      .filter(key => key.startsWith(path) && key !== path && key.split('/').length === path.split('/').length + 1)
      .map(async (key) => {
        const name = key.split('/').pop();
        const item = await this.fileSystem.getItem(key);
        return {
          name,
          type: item.type === 'directory' ? 'File Folder' : (item.type === 'network' ? 'Network' : 'File'),
          size: item.type === 'file' ? `${item.content.length} bytes` : '',
          path: key
        };
      }));
    return items;
  }

  async createFile(path, content = '') {
    await this.fileSystem.setItem(path, { type: 'file', content });
  }

  async createDirectory(path) {
    await this.fileSystem.setItem(path, { type: 'directory', content: null });
  }

  async createNetwork(path) {
    await this.fileSystem.setItem(path, { type: 'network', content: null });
  }

  async readFile(path) {
    const item = await this.fileSystem.getItem(path);
    return item && item.type === 'file' ? item.content : null;
  }

  async writeFile(path, content) {
    const item = await this.fileSystem.getItem(path);
    if (item && item.type === 'file') {
      await this.fileSystem.setItem(path, { ...item, content });
    }
  }

  async deleteItem(path) {
    const keys = await this.fileSystem.keys();
    const itemsToDelete = keys.filter(key => key.startsWith(path));
    for (const key of itemsToDelete) {
      await this.fileSystem.removeItem(key);
    }
  }

  async moveItem(oldPath, newPath) {
    const item = await this.fileSystem.getItem(oldPath);
    if (item) {
      await this.fileSystem.setItem(newPath, item);
      await this.fileSystem.removeItem(oldPath);
      
      // If it's a directory, move all its contents
      if (item.type === 'directory') {
        const keys = await this.fileSystem.keys();
        const itemsToMove = keys.filter(key => key.startsWith(oldPath + '/'));
        for (const key of itemsToMove) {
          const newItemPath = key.replace(oldPath, newPath);
          const itemValue = await this.fileSystem.getItem(key);
          await this.fileSystem.setItem(newItemPath, itemValue);
          await this.fileSystem.removeItem(key);
        }
      }
    }
  }

  async getFileTree() {
    const buildTree = async (path, name) => {
      const items = await this.getItems(path);
      const node = { name, path, type: 'File Folder', children: [] };
      
      for (const item of items) {
        if (item.type === 'File Folder') {
          node.children.push(await buildTree(`${path}/${item.name}`, item.name));
        } else {
          node.children.push({ ...item, children: [] });
        }
      }
      
      return node;
    };

    const rootTree = await buildTree('C:', 'My Computer');
    
    // Add network items at the top level
    const networkItems = await this.getItems('C:/');
    const networkNodes = networkItems
      .filter(item => item.type === 'Network')
      .map(item => ({ ...item, children: [] }));

    return [rootTree, ...networkNodes];
  }
}

const localFileManager = new LocalFileManager();

export default localFileManager;