import React, { useState, useEffect, useCallback } from 'react';
import folderIcon from './icons/folder-32x32.png';
import fileIcon from './icons/notepad-file-32x32.png';
import networkIcon from './icons/network-32x32.png';
import localFileManager from './LocalFileManager';
import tinyCloudManager from './TinyCloudManager';

const FileBrowser = ({ id, setWindows, openNotepad, defaultPath = 'C:' }) => {
  const [currentPath, setCurrentPath] = useState(defaultPath);
  const [items, setItems] = useState([]);
  const [fileTree, setFileTree] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const fetchItems = useCallback(async () => {
    let fetchedItems;
    if (currentPath.startsWith('TinyCloud:')) {
      fetchedItems = await tinyCloudManager.listFiles(currentPath);
      fetchFileTree();
      if (!isInitialized) {
        await tinyCloudManager.initialize();
        setIsInitialized(true);
      }
    } else {
      fetchedItems = await localFileManager.getItems(currentPath);
    }
    setItems(fetchedItems);
    console.log('Fetching items for path:', currentPath);
    console.log(fetchedItems);
  }, [currentPath]);

  useEffect(() => {
    localFileManager.initializeFileSystem();
    fetchFileTree();
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const fetchFileTree = async () => {
    try {
      const localTree = await localFileManager.getFileTree();
      const tinyCloudTree = await tinyCloudManager.getFileTree();
      setFileTree([tinyCloudTree, ...localTree]);
    } catch (error) {
      console.error('Error fetching file tree:', error);
      setFileTree([]);
    }
  };

  const updateWindowTitle = (title) => {
    setWindows(prevWindows => 
      prevWindows.map(window => 
        window.id === id ? { ...window, title } : window
      )
    );
  };

  useEffect(() => {
    updateWindowTitle(`Exploring - ${currentPath}`);
  }, [currentPath, id, setWindows]);

  const handleFileClick = async (item) => {
    if (item.type !== 'File Folder' && item.type !== 'Network') {
      let content;
      if (item.path.startsWith('TinyCloud:')) {
        content = await tinyCloudManager.downloadFile(item.path);
      } else {
        content = await localFileManager.readFile(item.path);
      }
      openNotepad(item.name, content);
    }
  };

  const renderTreeNode = (node) => (
    <li key={node.path}>
      <span 
        onClick={() => {
          if (node.type === 'File Folder' || node.type === 'Network') {
            setCurrentPath(node.path);
          } else {
            handleFileClick(node);
          }
        }}
        onContextMenu={(e) => handleContextMenu(e, node)} 
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <img 
          src={node.type === 'File Folder' ? folderIcon : node.type === 'Network' ? networkIcon : fileIcon} 
          alt={node.type} 
          style={{ width: '16px', height: '16px', marginRight: '4px', verticalAlign: 'middle' }} 
        />
        {node.name}
      </span>
      {node.children && node.children.length > 0 && (
        <ul>
          {node.children.map(renderTreeNode)}
        </ul>
      )}
    </li>
  );

  const handleDoubleClick = async (item) => {
    if (item.type === 'File Folder' || item.type === 'Network') {
      setCurrentPath(item.path);
    } else {
      handleFileClick(item);
    }
  };

  const goToParentDirectory = () => {
    if (currentPath !== 'C:' && currentPath !== 'TinyCloud:') {
      const parentPath = currentPath.split('/').slice(0, -1).join('/') || currentPath.split(':')[0] + ':';
      setCurrentPath(parentPath);
    }
  };

  const handleContextMenu = useCallback((event, item) => {
    event.preventDefault();
    setSelectedItem(item);
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  }, []);

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = async () => {
    if (selectedItem) {
      if (selectedItem.path.startsWith('TinyCloud:')) {
        await tinyCloudManager.deleteFile(selectedItem.path);
      } else {
        await localFileManager.deleteItem(selectedItem.path);
      }
      fetchItems();
      fetchFileTree();
    }
    handleClose();
  };

  const handleRename = async () => {
    if (selectedItem) {
      const newName = prompt('Enter new name:', selectedItem.name);
      if (newName && newName !== selectedItem.name) {
        const newPath = selectedItem.path.replace(selectedItem.name, newName);
        if (selectedItem.path.startsWith('TinyCloud:')) {
          await tinyCloudManager.moveItem(selectedItem.path, newPath);
        } else {
          await localFileManager.moveItem(selectedItem.path, newPath);
        }
        fetchItems();
        fetchFileTree();
      }
    }
    handleClose();
  };

  const handleNewFolder = async () => {
    const folderName = prompt('Enter new folder name:');
    if (folderName) {
      const newFolderPath = `${currentPath}/${folderName}`;
      if (currentPath.startsWith('TinyCloud:')) {
        await tinyCloudManager.createDirectory(newFolderPath);
      } else {
        await localFileManager.createDirectory(newFolderPath);
      }
      fetchItems();
      fetchFileTree();
    }
    handleClose();
  };

  const handleNewFile = async () => {
    const fileName = prompt('Enter new file name:');
    if (fileName) {
      const newFilePath = `${currentPath}/${fileName}`;
      if (currentPath.startsWith('TinyCloud:')) {
        await tinyCloudManager.uploadFile(newFilePath, '');
      } else {
        await localFileManager.createFile(newFilePath);
      }
      fetchItems();
      fetchFileTree();
    }
    handleClose();
  };

  const handleRefresh = useCallback(() => {
    fetchItems();
    fetchFileTree();
  }, [fetchItems]);

  return (
    <div className="file-browser" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#c0c0c0' }}>
      <div className="window-menu" style={{ display: 'flex', borderBottom: '1px solid #808080' }}>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }} onClick={() => setCurrentPath('C:')}>Home</div>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }} onClick={goToParentDirectory}>Parent</div>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }} onClick={handleRefresh}>Refresh</div>
        <div className="menu-item" style={{ padding: '2px 6px', cursor: 'default' }} onClick={async () => {
          if (window.confirm('Are you sure you want to reset TinyCloud? This will delete all files.')) {
            try {
              await tinyCloudManager.reset();
              handleRefresh();
              alert('TinyCloud has been reset successfully.');
            } catch (error) {
              console.error('Failed to reset TinyCloud:', error);
              alert('Failed to reset TinyCloud. Please try again.');
            }
          }
        }}>Reset TinyCloud</div>
      </div>
      <div style={{ borderBottom: '1px solid #808080', padding: '4px', display: 'flex', alignItems: 'center' }}>
        <span>{currentPath}</span>
      </div>
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }} onContextMenu={(e) => handleContextMenu(e, { path: currentPath, type: 'File Folder' })}>
        <div className="folders" style={{ width: '200px', borderRight: '1px solid #808080', overflowY: 'auto', height: '100%', backgroundColor: 'white' }}>
          <ul className="tree-view" style={{ margin: 0, padding: '8px' }}>
            {fileTree && fileTree.length > 0 ? fileTree.map(renderTreeNode) : <li>No files found</li>}
          </ul>
        </div>
        <div className="file-list" style={{ flex: 1, overflowY: 'auto', height: '100%', backgroundColor: 'white' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#c0c0c0' }}>
                <th style={{ textAlign: 'left', padding: '4px' }}>Name</th>
                <th style={{ textAlign: 'left', padding: '4px' }}>Size</th>
                <th style={{ textAlign: 'left', padding: '4px' }}>Type</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index} onDoubleClick={() => handleDoubleClick(item)} onContextMenu={(e) => handleContextMenu(e, item)}>
                  <td style={{ padding: '4px' }}>
                    <img 
                      src={item.type === 'File Folder' ? folderIcon : item.type === 'Network' ? networkIcon : fileIcon} 
                      alt={item.type} 
                      style={{ width: '16px', height: '16px', marginRight: '4px', verticalAlign: 'middle' }} 
                    />
                    {item.name}
                  </td>
                  <td style={{ padding: '4px' }}>{item.size}</td>
                  <td style={{ padding: '4px' }}>{item.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="status-bar" style={{ borderTop: '1px solid #808080', padding: '4px' }}>
        {items.length} object(s)
      </div>
      {contextMenu !== null && (
        <div
          className="context-menu"
          style={{
            position: 'fixed',
            top: contextMenu.mouseY,
            left: contextMenu.mouseX,
            zIndex: 1000,
          }}
        >
          {selectedItem && selectedItem.type !== 'Network' && (
            <>
              <div className="context-menu-item" onClick={handleRename}>Rename</div>
              <div className="context-menu-item" onClick={handleDelete}>Delete</div>
            </>
          )}
          <div className="context-menu-item" onClick={handleNewFolder}>New Folder</div>
          <div className="context-menu-item" onClick={handleNewFile}>New File</div>
        </div>
      )}
    </div>
  );
};

export default FileBrowser;