import React from 'react';
import Draggable from 'react-draggable';

const Window = ({ id, title, children, position, size, onClose, onDrag, isActive, onActivate, onMaximize, onMinimize, zIndex, isMaximized }) => {
  const defaultSize = { width: 300, height: 200 };
  const windowSize = isMaximized ? { width: '100%', height: '100%' } : (size || defaultSize);

  return (
    <Draggable
      handle=".title-bar"
      position={isMaximized ? { x: 0, y: 0 } : position}
      onDrag={(e, data) => !isMaximized && onDrag({ x: data.x, y: data.y })}
      disabled={isMaximized}
    >
      <div 
        className="window" 
        style={{
          position: 'absolute',
          zIndex: isActive ? 9999 : zIndex,
          border: isActive ? '2px solid #0000ff' : '2px solid #c0c0c0',
          width: windowSize.width,
          height: windowSize.height
        }}
        onClick={onActivate}
      >
        <div className="title-bar">
          <div className="title-bar-text">{title}</div>
          
          <div className="title-bar-controls">
            <button aria-label="Minimize" onClick={onMinimize}></button>
            <button aria-label="Maximize" onClick={onMaximize}></button>
            <button aria-label="Close" onClick={onClose}></button>
          </div>
        </div>
        <div className="window-content" style={{ height: 'calc(100% - 20px)', overflow: 'auto' }}>
          {children}
        </div>
      </div>
    </Draggable>
  );
};

export default Window;