import { SSX } from '@spruceid/ssx';

class TinyCloudManager {
  constructor() {
    this.ssx = null;
    this.connected = false;
  }

  async connect() {
    if (this.connected) {
      return;
    }
    try {
      this.ssx = new SSX({
        siweConfig: { statement: 'Sign in to TinyCloud!' },
        modules: {
          storage: {
            hosts: ['https://moonscape.ngrok.io'],
            autoCreateNewOrbit: true,
            prefix: 'tc-demo',
          }
        }
      });

      await this.ssx.signIn();
      this.connected = true;
      console.log('Connected to TinyCloud');
    } catch (error) {
      console.error('Failed to connect to TinyCloud:', error);
      if (error.code === "INVALID_ARGUMENT") {
        throw new Error("A Web3 wallet like MetaMask is required for TinyCloud at this moment.");
      } else if (error.code === 4001) {
        throw new Error("You must Sign-in with Ethereum in order to use TinyCloud.");
      } else {
        throw error;
      }
    }
  }

  async disconnect() {
    if (this.ssx) {
      await this.ssx.signOut();
      this.connected = false;
      console.log('Disconnected from TinyCloud');
    }
  }

  async initialize() {
    try {
      await this.connect();
      const files = await this.listFiles('/');
      const isInitialized = files.some(file => file.name === 'welcome.txt');
      
      if (!isInitialized) {
        console.log('Initializing TinyCloud');
        await this.uploadFile('welcome.txt', 'Welcome to TinyCloud!');
      }
    } catch (error) {
      console.error('Failed to initialize TinyCloud:', error);
      throw error;
    }
  }

  async reset() {
    if (!this.connected) {
      await this.connect();
    }
    await this.ssx.storage.deleteAll();
  }

  async listFiles(path) {
    if (!this.connected) {
      await this.connect();
    }
    const cleanPath = path.replace('TinyCloud:/', '').replace(/\/+/g, '/');
    try {
      const response = await this.ssx.storage.list({ path: cleanPath });
      if (!response || !response.data) {
        console.warn('Received empty response from SSX storage');
        return [];
      }
      return response.data
        .filter(item => item !== cleanPath && item !== cleanPath + '/') // Filter out the current directory
        .map(item => {
          const name = item.split('/').pop();
          return {
            name: name || item, // Use the full item if name is empty (root case)
            type: item.endsWith('/') ? 'File Folder' : 'File',
            path: `TinyCloud:/${item}`,
            size: '' // Size information is not available from SSX storage
          };
        });
    } catch (error) {
      console.error('Error listing files:', error);
      return [];
    }
  }

  async downloadFile(path) {
    if (!this.connected) {
      await this.connect();
    }
    const cleanPath = path.replace('TinyCloud:/', '');
    const result = await this.ssx.storage.get(cleanPath);
    return result.data;
  }

  async uploadFile(path, content) {
    if (!this.connected) {
      await this.connect();
    }
    const cleanPath = path.replace('TinyCloud:/', '');
    const result = await this.ssx.storage.put(cleanPath, content);
    return result.data;
  }

  async deleteFile(path) {
    if (!this.connected) {
      await this.connect();
    }
    const cleanPath = path.replace('TinyCloud:/', '');
    return this.ssx.storage.delete(cleanPath);
  }

  async createDirectory(path) {
    if (!this.connected) {
      await this.connect();
    }
    const cleanPath = path.replace('TinyCloud:/', '');
    // Creating an empty file with a trailing slash to represent a directory
    await this.ssx.storage.put(`${cleanPath}`, '');
  }

  async moveItem(oldPath, newPath) {
    if (!this.connected) {
      await this.connect();
    }
    const cleanOldPath = oldPath.replace('TinyCloud:/', '');
    const cleanNewPath = newPath.replace('TinyCloud:/', '');

    // Download the item
    const content = await this.downloadFile(cleanOldPath);

    // Upload to new location
    await this.uploadFile(cleanNewPath, content);

    // Delete from old location
    await this.deleteFile(cleanOldPath);

    // If it's a directory, move all its contents
    if (cleanOldPath.endsWith('/')) {
      const items = await this.listFiles(cleanOldPath);
      for (const item of items) {
        const itemOldPath = `${cleanOldPath}${item.name}`;
        const itemNewPath = `${cleanNewPath}${item.name}`;
        await this.moveItem(itemOldPath, itemNewPath);
      }
    }
  }

  async getFileTree() {
    const buildTree = async (path, name, type = 'File Folder') => {
        console.log('Building tree for path:', path);
      try {
        const items = this.connected ? await this.listFiles(path) : [];
        const node = { name, path, type, children: [] };
        
        for (const item of items) {
          if (item.type === 'File Folder') {
            // Ensure we're not creating an infinite loop by checking if the item's path is different from the current path
            if (item.path !== path) {
              node.children.push(await buildTree(item.path, item.name));
            } else {
              console.warn(`Skipping folder with same path as parent: ${item.path}`);
            }
          } else {
            node.children.push({ ...item, children: [] });
          }
        }
        
        return node;
      } catch (error) {
        console.error('Error building file tree:', error);
        return { name, path, type, children: [] };
      }
    };

    try {
      return await buildTree('TinyCloud:', 'TinyCloud', "Network");
    } catch (error) {
      console.error('Error getting file tree:', error);
      return { name: 'TinyCloud', path: 'TinyCloud:', type: 'Network', children: [] };
    }
  }
}

export default new TinyCloudManager();