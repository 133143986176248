import React from 'react';
import Window from './Window';

const WindowManager = ({ windows, closeWindow, onDrag, activeWindow, setActiveWindow, maximizeWindow, minimizeWindow }) => {
  return (
    <>
      {windows.map((window, index) => (
        <Window
          key={window.id}
          {...window}
          onClose={() => closeWindow(window.id)}
          onDrag={(newPosition) => onDrag(window.id, newPosition)}
          isActive={activeWindow === window.id}
          onActivate={() => setActiveWindow(window.id)}
          onMaximize={() => maximizeWindow(window.id)}
          onMinimize={() => minimizeWindow(window.id)}
          zIndex={windows.length - index}
        >
          <window.component {...window.props} />
        </Window>
      ))}
    </>
  );
};

export default WindowManager;